/* ----------
    // **** PRINCIPALES **** //
---------- */

    body {
        background-image: url(../../img/bg.jpg);
    }

    h1, h2, h3, h4, h5 {
        font-weight: 700;
    }

    pre {
        font-family: "Segoe UI", "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
        padding: 0;
        background-color: transparent;
        white-space: pre-wrap;
        word-wrap: break-word;
        word-break: normal;
        font-size: 15px;
        border: 0;
    }

/* ----------
    // **** CABECERA **** //
---------- */

    .bg-cabecera {
        background-image: url(../../img/cabecera.jpg);
    }

    .logo {
        margin: 16px 0;
        height: 76px;
    }

    .cabecera-info {
        padding-top: 16px;
        font-weight: 700;
    }

    .cabecera-info h3 {
        margin: 0;
    }

    .cabecera-info .enlace {
        margin-left: 12px;
    }

/* ----------
    // **** PIE **** //
---------- */

    .pie {
        margin: 0;
        padding: 24px 0;
    }

    .pie-social {
        margin-top: 24px;
        padding: 9px;
        border-radius: 5px;
    }

    .copyright {
        padding: 20px 0;
    }

/* ----------
    // **** ESPECIALES **** //
---------- */

    /* NAVBAR */
    .navbar i.fa {
        margin-right: 8px;
    }

    .navbar-nav li a {
        text-transform: uppercase;
        font-weight: 700;
    }

    /* PANEL */
    .panel-title, .modal-title {
        text-transform: uppercase;
    }

    .panel-title {
        text-shadow: 1px 1px 0 #666;
    }

    .panel-title i.fa {
        margin-right: 8px;
    }

    /* BOTONES */
    .btn {
        text-transform: uppercase;
        font-weight: 700;
    }

    .btn i.fa {
        margin-right: 8px;
    }

    /* NAVTABS */
    .nav-tabs i.fa {
        margin-right: 6px;
    }

    /* LIST GROUP */
    a.list-group-item i.fa {
        margin-right: 16px;
    }

    /* TABLAS */
    table.table tbody tr td {
        vertical-align: middle;
    }

    /*td {
        font-size: 14px;
    }*/

    /* FORMULARIOS */
    .form-group.required .control-label:after, .form-group.required label:after {
        content: " *";
        color: red;
    }

    /* DROPDOWNS */
    .dropdown-menu i.fa {
        margin-right: 10px;
    }

/* ----------
    // **** OTROS **** //
---------- */

    .separador-inicio {
        height: 4px;
    }

    .titulo {
        padding: 20px 0;
    }

    .titulo h2 {
        margin: 0;
    }
